import { AppState } from '@core/states/app.state';
import { createSelector } from '@ngrx/store';
import { AuthState } from '@core/states/auth/auth.state';
import { hasRole } from '@core/helpers/permissions/has-role';
import { UserRole } from '@corezilla/api';

/**
 * Select to select auth state from app state
 * **/
export const selectAuth = (state: AppState) => state.auth;

/**
 * Selector to select current user of auth state
 * **/
export const selectUser = createSelector(
  selectAuth,
  (state: AuthState) => state.user
);

/**
 * Selector to select current view state of auth state
 * **/
export const selectViewState = createSelector(
  selectAuth,
  (state: AuthState) => state.viewState
);

/**
 * Selector to select current crud state of auth state
 * **/
export const selectCrudState = createSelector(
  selectAuth,
  (state: AuthState) => state.crudState
);

/**
 * Selector to select error of auth state
 * **/
export const selectViewStateError = createSelector(
  selectAuth,
  (state: AuthState) => state.viewStateError
);

/**
 * Selector to select the error message of auth state error
 * **/
export const selectViewStateErrorMessage = createSelector(
  selectViewStateError,
  error => error?.message
);

/**
 * Selector to select error of auth state
 * **/
export const selectCrudStateError = createSelector(
  selectAuth,
  (state: AuthState) => state.crudStateError
);

/**
 * Selector to select the error message of auth state error
 * **/
export const selectCrudStateErrorMessage = createSelector(
  selectCrudStateError,
  error => error?.message
);

/**
 * Selector to select if user is logged in
 * **/
export const selectIsLoggedIn = createSelector(selectUser, user => !!user);

/**
 * Selector to select if user has given roles
 * **/
export const selectHasRole = (roles: UserRole[] = []) =>
  createSelector(selectUser, user => hasRole(user, roles));
